import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  static targets = [ "gsnNumberInput" ]

  connect() {
    this.formatNumber();
  }

  formatNumber() {
    let newValue = this.gsnNumberInputTarget.value
    newValue = newValue.replace(/ /g,"");
    newValue = newValue.toUpperCase();

    this.gsnNumberInputTarget.value = newValue;
  }

}
