import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    initialCurrentLeaderboardId: String,
    initialCurrentLeaderboardPage: String,
    refreshInterval: Number,
    url: String,
  }

  connect() {
    if (this.hasRefreshIntervalValue) {
      this.startRefreshing()
    }
  }

  disconnect() {
    this.stopRefreshing()
  }

  startRefreshing() {
    this.refreshTimer = setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  stopRefreshing() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  load() {
    const boardElement = document.getElementById('board')
    const currentLeaderboardId = boardElement.dataset.currentLeaderboardId
    const currentLeaderboardPage = boardElement.dataset.currentLeaderboardPage
    let url;
    if(currentLeaderboardId) {
      url = this.urlValue + '?current_leaderboard_id=' + currentLeaderboardId + '&current_leaderboard_page=' + currentLeaderboardPage
    } else {
      url = this.urlValue + '?current_leaderboard_id=' + this.initialCurrentLeaderboardIdValue + '&current_leaderboard_page=' + this.initialCurrentLeaderboardPageValue
    }


    fetch(url)
      .then(response => response.text())
      .then(html => this.element.innerHTML = html)
  }
}
